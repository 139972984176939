<template>
  <div class="blogs">
    <div class="row align-items-center justify-content-between mx-0 mb-3">
      <h1 class="fw-600 fs-24 mb-0">{{ blog.id ? 'Editar Publicação' : 'Nova Publicação' }}</h1>
      <button class="btn btn-blue my-3 my-sm-0" @click="blog.id ? updateBlog(blog) : storeBlog(blog)">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clip-path="url(#clip0_1_1170)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9549 4.25916C18.1893 4.49357 18.3209 4.81146 18.3209 5.14291C18.3209 5.47437 18.1893 5.79225 17.9549 6.02666L8.58577 15.3958C8.46196 15.5197 8.31496 15.6179 8.15317 15.6849C7.99139 15.752 7.81798 15.7865 7.64286 15.7865C7.46774 15.7865 7.29433 15.752 7.13254 15.6849C6.97076 15.6179 6.82376 15.5197 6.69994 15.3958L2.04494 10.7417C1.92555 10.6264 1.83032 10.4884 1.76481 10.3359C1.6993 10.1834 1.66482 10.0194 1.66338 9.85341C1.66194 9.68744 1.69356 9.52284 1.75641 9.36922C1.81926 9.2156 1.91208 9.07603 2.02945 8.95867C2.14681 8.8413 2.28638 8.74849 2.44 8.68563C2.59362 8.62278 2.75822 8.59116 2.92419 8.5926C3.09017 8.59404 3.25419 8.62852 3.4067 8.69403C3.5592 8.75955 3.69713 8.85477 3.81244 8.97416L7.64244 12.8042L16.1866 4.25916C16.3027 4.143 16.4405 4.05086 16.5922 3.98799C16.7439 3.92512 16.9066 3.89276 17.0708 3.89276C17.235 3.89276 17.3976 3.92512 17.5493 3.98799C17.701 4.05086 17.8389 4.143 17.9549 4.25916Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_1_1170">
          <rect width="20" height="20" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        {{ blog.id ? 'Editar Publicação' : 'Criar Publicação' }}
      </button>
    </div>
    <div class="row mt-3">
      <!-- Cards da Direita -->
      <div class="col-8">
        <div class="card mb-3">
          <h6 class="card-title c-black mb-2">Título</h6>
          <input type="text" class="form-control" placeholder="Digite aqui..." v-model="blog.title">
          <h6 class="card-title c-black mb-2 mt-4">Descrição</h6>
          <input type="text" class="form-control" placeholder="Digite aqui..." v-model="blog.description">
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col d-flex align-items-center justify-content-between">
                <h6 class="card-title c-black mb-0">Conteúdo</h6>
                <!-- <button class="btn btn-outline-blue" @click="addBlock('text')"> -->
                <!-- <button class="btn btn-outline-blue"  data-toggle="modal" data-target="#blockModal">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99996 15.8333V4.16667M15.8333 10H4.16663" stroke="#0062FF" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                  Novo Bloco
                </button> -->
              </div>
            </div>
            <div class="row my-2" v-for="(block_row, index_row) in blog.blocks" :key="index_row">
              <!-- <draggable class="w-100" v-model="blog.blocks" :options="{group:'people'}" @start="drag=true" @end="drag=false"> -->
                <div class="col-md" v-for="(block_col, index_col) in block_row" :key="index_col">
                  <div class="item" v-for="(block_item, index_item) in block_col" :key="index_item">
                    <TextBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_text_block="block_item" v-if="block_item.type == 'text'" class="mt-2"/>
                    <MidiaBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_midia_block="block_item" v-if="block_item.type == 'midia'" class="mt-2"/>
                    <ButtonBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_button_block="block_item" v-if="block_item.type == 'button'" class="mt-2"/>
                  </div>
                </div>
              <!-- </draggable> -->
            </div>
            <div class="row my-2" v-for="block_row in blog.blocks" :key="block_row.id">
              <!-- <draggable class="w-100" v-model="blog.blocks" :options="{group:'people'}" @start="drag=true" @end="drag=false"> -->
                <div class="col-md" v-if="block_row.length == 1">
                  <div v-for="block_col in block_row" :key="block_col.id">
                    <TextBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_text_block="block_col" v-if="block_col.type == 'text'" class="mt-2"/>
                    <MidiaBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_midia_block="block_col" v-if="block_col.type == 'midia'" class="mt-2"/>
                    <ButtonBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_button_block="block_col" v-if="block_col.type == 'button'" class="mt-2"/>
                    <TextMidiaBlock @openDelete="openDeleteModal({
                      row: index_row,
                      col: block_row.length > 1 ? index_col : null,
                      item: block_col.length > 1 ? index_item : null
                    })" :_text_midia="block_row" v-if="block_row[0].type == 'text_midia'" class="mt-2"/>
                      <!-- <TextFormBlock /> -->
                      <!-- <MidiaFormBlock /> -->
                  </div>
                </div>
                <div class="col-md" v-else-if="block_row.length >= 2">
                  <div class="" >
                    <!-- <TextButtonBlock :_text_button="block" v-if="block.type == 'text_button'" class="mt-2"/> -->
                    <!-- <TextButtonMidiaBlock :_text_button="block" v-if="block.type == 'text_midia_button'" class="mt-2"/> -->
                      <!-- <FormBlock :_text_button="block" v-if="block.type == 'text_midia_button'" class="mt-2"/> -->
                  </div>
                </div>
              <!-- </draggable> -->
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <button class="btn btn-outline-blue btn-block text-center"  data-toggle="modal" data-target="#blockModal">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99996 15.8333V4.16667M15.8333 10H4.16663" stroke="#0062FF" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                  Novo Bloco
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Cards da Esquerda -->
      <div class="col-4">
        <div class="card mb-3">
          <h6 class="card-title c-black mb-0">Detalhes</h6>
          <hr class="mt-2 mb-4">
          <div class="">
            <label for="">Autor*</label>
            <input type="text" class="form-control" placeholder="Nome Sobrenome" v-model="blog.author">
          </div>
          <hr class="my-4">
          <div class="">
            <label for="">Categoria*</label>

            <div class="row align-items-center my-3">
              <div class="col-md-7">
                <div class="position-relative">
                  <input type="text" class="form-control" placeholder="Pesquisar" v-model="searchCategory" @change="findCategories(searchCategory)">
                  <svg class="position-search-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path d="M9.70367 8.87633L7.49844 6.67109C8.02936 5.9643 8.31597 5.10399 8.315 4.22C8.315 1.96203 6.47797 0.125 4.22 0.125C1.96203 0.125 0.125 1.96203 0.125 4.22C0.125 6.47797 1.96203 8.315 4.22 8.315C5.10399 8.31597 5.9643 8.02936 6.67109 7.49844L8.87633 9.70367C8.98796 9.80346 9.13355 9.85673 9.28322 9.85254C9.43289 9.84835 9.57528 9.78702 9.68115 9.68115C9.78702 9.57528 9.84835 9.43289 9.85254 9.28322C9.85673 9.13355 9.80346 8.98796 9.70367 8.87633ZM1.295 4.22C1.295 3.64149 1.46655 3.07597 1.78795 2.59496C2.10935 2.11394 2.56618 1.73904 3.10065 1.51765C3.63513 1.29627 4.22325 1.23834 4.79064 1.3512C5.35803 1.46407 5.87922 1.74264 6.28829 2.15171C6.69736 2.56078 6.97594 3.08197 7.0888 3.64936C7.20166 4.21676 7.14373 4.80488 6.92235 5.33935C6.70096 5.87382 6.32606 6.33065 5.84504 6.65205C5.36403 6.97345 4.79851 7.145 4.22 7.145C3.44453 7.14407 2.70108 6.8356 2.15274 6.28726C1.6044 5.73892 1.29593 4.99547 1.295 4.22Z" fill="#696974"/>
                  </svg>
                </div>
              </div>
              <div class="col-md-5">
                <button class="btn btn-blue" @click="openCreateModal()">Criar Categoria</button>
              </div>
            </div>

            <!-- Rota de categorias -->
            <div class="d-flex align-items-center border-bottom py-2">
              <button class="btn btn-home" @click="index({page: 1, name: null})">
                <svg xmlns="http://www.w3.org/2000/svg" class="d-block" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M2 5.99992L8 1.33325L14 5.99992V13.3333C14 13.6869 13.8595 14.026 13.6095 14.2761C13.3594 14.5261 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5261 2.39052 14.2761C2.14048 14.026 2 13.6869 2 13.3333V5.99992Z" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 14.6667V8H10V14.6667" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <!-- <div v-if="details.search">
                <div class="category pointer" v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">
                  <p class="mb-0">{{ breadcrumb.description }}</p>
                </div>
              </div> -->
              <div class="category pointer" v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">
                <p class="mb-0">{{ breadcrumb.description }}</p>
              </div>
              <div  v-for="category in categories" :key="category.id">
                <p class="fw-400 fs-14 c-grey mb-0 pointer" v-if="searchCategory" @click="setBreadcrumbs(breadcrumb_search)">
                  <span v-for="(subcategory, i) in setCategoryBreadcrumb(category)" :key="subcategory.id" class="mr-1">
                    <span v-if="i != 0">{{ ">" }}</span>
                    {{subcategory.description }}
                  </span>
                </p>
                <!-- <p class="fw-400 fs-14 c-grey mb-0 pointer" v-else @click="show(category.id)">{{ category.description }}</p> -->
              </div>
                <div class="category pointer" v-if="breadcrumbs.length >= 1" @click="breadcrumbs[breadcrumbs.length - 1].parent_id ? back(breadcrumbs[breadcrumbs.length - 1].parent_id) : index({page: 1, name: null})">
                  <svg xmlns="http://www.w3.org/2000/svg" class="ml-1" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <g clip-path="url(#clip0_85_603)">
                      <path d="M7.89797 4.89797L6.79688 6L7.89938 7.10203C8.00505 7.2077 8.06442 7.35103 8.06442 7.50047C8.06442 7.64991 8.00505 7.79323 7.89938 7.89891C7.7937 8.00458 7.65038 8.06394 7.50094 8.06394C7.3515 8.06394 7.20817 8.00458 7.1025 7.89891L6 6.79688L4.89797 7.89937C4.7923 8.00505 4.64898 8.06441 4.49953 8.06441C4.35009 8.06441 4.20677 8.00505 4.1011 7.89937C3.99542 7.7937 3.93606 7.65038 3.93606 7.50094C3.93606 7.35149 3.99542 7.20817 4.1011 7.1025L5.20313 6L4.10203 4.89797C4.04971 4.84565 4.0082 4.78353 3.97989 4.71516C3.95157 4.6468 3.937 4.57353 3.937 4.49953C3.937 4.35009 3.99636 4.20677 4.10203 4.10109C4.20771 3.99542 4.35103 3.93606 4.50047 3.93606C4.64991 3.93606 4.79324 3.99542 4.89891 4.10109L6 5.20312L7.10203 4.10063C7.20771 3.99495 7.35103 3.93559 7.50047 3.93559C7.64991 3.93559 7.79324 3.99495 7.89891 4.10063C8.00458 4.2063 8.06395 4.34962 8.06395 4.49906C8.06395 4.64851 8.00458 4.79183 7.89891 4.8975L7.89797 4.89797ZM11.0625 6C11.0625 7.00127 10.7656 7.98005 10.2093 8.81257C9.65304 9.6451 8.86239 10.294 7.93734 10.6771C7.01229 11.0603 5.99439 11.1606 5.01236 10.9652C4.03033 10.7699 3.12828 10.2877 2.42027 9.57973C1.71227 8.87173 1.23011 7.96967 1.03478 6.98764C0.839439 6.00562 0.939694 4.98772 1.32286 4.06266C1.70603 3.13761 2.3549 2.34696 3.18743 1.79069C4.01995 1.23441 4.99873 0.9375 6 0.9375C7.3422 0.938989 8.62901 1.47284 9.57809 2.42192C10.5272 3.371 11.061 4.6578 11.0625 6ZM9.9375 6C9.9375 5.22124 9.70657 4.45996 9.27391 3.81244C8.84126 3.16492 8.2263 2.66024 7.50682 2.36222C6.78733 2.0642 5.99563 1.98623 5.23183 2.13816C4.46803 2.29009 3.76644 2.6651 3.21577 3.21577C2.6651 3.76644 2.29009 4.46803 2.13816 5.23183C1.98623 5.99563 2.06421 6.78733 2.36223 7.50682C2.66025 8.2263 3.16493 8.84125 3.81244 9.27391C4.45996 9.70657 5.22124 9.9375 6 9.9375C7.04395 9.93638 8.04482 9.52118 8.783 8.783C9.52118 8.04482 9.93639 7.04395 9.9375 6Z" fill="#0062FF"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_85_603">
                        <rect width="12" height="12" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
            </div>
            <div v-for="category in categories" :key="category.id">
              <p class="fw-400 fs-14 c-grey mb-0 pointer" @click="show(category.id)">{{ category.description }}</p>
            </div>
          </div>
          <!-- </div> -->
          <div class="mb-0 mt-4">
            <label for="">Foto de Capa*</label>
            <div class="d-block">
              <MidiaBlock :_midia_block="blog.coverImage" paramType="thumbnail"/>
            </div>
            <!-- <div class="custom-file">
              <input type="file" id="fileInput" class="input-file" ref="fileInput" @change="updateFileName()">
              <label for="fileInput" class="file-label">
                Anexar
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M10.1146 4.66667L5.72393 9.05734C5.59659 9.18034 5.49501 9.32746 5.42513 9.49014C5.35525 9.65281 5.31847 9.82777 5.31693 10.0048C5.31539 10.1818 5.34913 10.3574 5.41617 10.5213C5.48321 10.6851 5.58221 10.834 5.70741 10.9592C5.8326 11.0844 5.98146 11.1834 6.14533 11.2504C6.30919 11.3175 6.48476 11.3512 6.6618 11.3497C6.83884 11.3481 7.0138 11.3114 7.17647 11.2415C7.33914 11.1716 7.48627 11.07 7.60927 10.9427L11.8853 6.55201C12.371 6.04907 12.6398 5.37546 12.6337 4.67627C12.6277 3.97708 12.3472 3.30825 11.8528 2.81382C11.3584 2.3194 10.6895 2.03895 9.99034 2.03288C9.29114 2.0268 8.61754 2.29559 8.1146 2.78134L3.83793 7.17134C3.08772 7.92155 2.66626 8.93905 2.66626 10C2.66626 11.061 3.08772 12.0785 3.83793 12.8287C4.58814 13.5789 5.60564 14.0003 6.6666 14.0003C7.72755 14.0003 8.74506 13.5789 9.49527 12.8287L13.6666 8.66667" stroke="#0E1410" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </label>
              <span id="fileName" class="file-name">{{ details.fileName }}</span>
            </div> -->
          </div>
        </div>

        <div class="card">
          <h6 class="card-title c-black mb-0">Status</h6>
          <hr class="mt-2 mb-4">
          <label for="">Publicação*</label>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center pointer">
              <input type="radio" name="status" v-model="blog.status" :value="1" id="active">
              <label class="radio" for="active">Ativo</label>
            </div>
            <div class="d-flex align-items-center pointer">
              <input type="radio" name="status" v-model="blog.status" :value="0" id="inactive">
              <label class="radio" for="inactive">Inativo</label>
            </div>
            <div class="d-flex align-items-center pointer">
              <input type="radio" name="status" v-model="blog.status" :value="2" id="scheduled">
              <label class="radio" for="scheduled">Agendar</label>
            </div>
          </div>
          <div v-if="blog.status == 2">
            <hr class="my-4">
            <h6 class="card-title c-black mb-4">Agendamento</h6>
            <div class="d-flex">
              <div class="">
                <label for="">Data e hora</label>
                <input type="datetime-local" v-model="blog.scheduled_at" class="fs-16">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <LoadingComponent :loading="loading" />

    <!-- Block Modal -->
    <div class="modal fade" id="blockModal" tabindex="-1" role="dialog" aria-labelledby="blockModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="d-flex align-items-center justify-content-between border-bottom">
            <h5 class="fw-600 fs-16 mb-0" id="blockModalTitle">Selecione o tipo de bloco desejado</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <!-- <span aria-hidden="true">&times;</span> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.71995 3.72006C3.86058 3.57961 4.0512 3.50072 4.24995 3.50072C4.4487 3.50072 4.63933 3.57961 4.77995 3.72006L7.99995 6.94006L11.22 3.72006C11.3116 3.62133 11.4282 3.54916 11.5575 3.51115C11.6867 3.47313 11.8238 3.47068 11.9544 3.50406C12.0849 3.53743 12.204 3.60539 12.2991 3.70078C12.3943 3.79617 12.4619 3.91545 12.495 4.04606C12.5284 4.17643 12.526 4.31341 12.4882 4.44257C12.4504 4.57173 12.3784 4.68832 12.28 4.78006L9.05995 8.00006L12.28 11.2201C12.3787 11.3117 12.4508 11.4283 12.4889 11.5576C12.5269 11.6868 12.5293 11.8239 12.496 11.9545C12.4626 12.085 12.3946 12.2041 12.2992 12.2992C12.2038 12.3944 12.0846 12.462 11.954 12.4951C11.8236 12.5285 11.6866 12.5261 11.5574 12.4883C11.4283 12.4505 11.3117 12.3785 11.22 12.2801L7.99995 9.06006L4.77995 12.2801C4.63769 12.4125 4.44964 12.4846 4.25532 12.4812C4.061 12.4779 3.87555 12.3993 3.73795 12.2621C3.60071 12.1245 3.52215 11.939 3.5188 11.7447C3.51544 11.5504 3.58754 11.3623 3.71995 11.2201L6.93995 8.00006L3.71995 4.78006C3.5795 4.63943 3.50061 4.44881 3.50061 4.25006C3.50061 4.05131 3.5795 3.86068 3.71995 3.72006Z" fill="#171725"/>
              </svg>
            </button>
          </div>
          <div class="row pt-3">
            <div class="col-6 my-2 px-2">
              <div class="block" @click="addBlock('text')">
                <img src="/img/blog/texto.jpg" class="d-block w-100 br-15" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Texto</p>
              </div>
            </div>
            <div class="col-6 my-2 px-2">
              <div class="block" @click="addBlock('midia')">
                <img src="/img/blog/midia.jpg" class="d-block w-100 br-15" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Mídia</p>
              </div>
            </div>
            <div class="col-6 my-2 px-2">
              <div class="block" @click="addBlock('button')">
                <img src="/img/blog/botao.jpg" class="d-block w-100 br-15" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Botão</p>
              </div>
            </div>
            <!-- <div class="col-6 my-2 px-2">
              <div class="block" @click="addBlock('text_midia')">
                <img src="/img/blog/text-midia.jpg" class="d-block w-100 br-15" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Texto e Mídia</p>
              </div>
            </div> -->
            <div class="col-6 my-2 px-2">
              <div class="block" @click="addBlock('text_button')">
                <img src="/img/blog/text-botao.jpg" class="d-block w-100 br-15" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Texto e Botão</p>
              </div>
            </div>
            <!-- <div class="col-4 my-2 px-2">
              <div class="block" @click="addBlock('text_midia_button')">
                <img src="/img/blog/texto-midia-botao.jpg" class="d-block w-100 br-10" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Texto, Mídia e Botão</p>
              </div>
            </div>
            <div class="col-4 mt-2 px-2">
              <div class="block">
                <img src="/img/blog/formulario.jpg" class="d-block w-100 br-10" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Formulário</p>
              </div>
            </div>
            <div class="col-4 mt-2 px-2">
              <div class="block">
                <img src="/img/blog/texto-formulario.jpg" class="d-block w-100 br-10" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Texto e Formulário</p>
              </div>
            </div>
            <div class="col-4 mt-2 px-2">
              <div class="block">
                <img src="/img/blog/midia-formulario.jpg" class="d-block w-100 br-10" alt="">
                <p class="fw-400 fs-14 c-grey mb-0">Mídia e Formulário</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Create Category Modal -->
    <div class="modal fade" id="createCategoryModal" tabindex="-1" role="dialog" aria-labelledby="createCategoryModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content modal-category">
          <div class="d-flex align-items-center justify-content-between border-bottom">
            <h5 class="fw-600 fs-16 mb-0" id="blockModalTitle">{{ category.id ? 'Editar categoria' : 'Criar categoria' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <!-- <span aria-hidden="true">&times;</span> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.71995 3.72006C3.86058 3.57961 4.0512 3.50072 4.24995 3.50072C4.4487 3.50072 4.63933 3.57961 4.77995 3.72006L7.99995 6.94006L11.22 3.72006C11.3116 3.62133 11.4282 3.54916 11.5575 3.51115C11.6867 3.47313 11.8238 3.47068 11.9544 3.50406C12.0849 3.53743 12.204 3.60539 12.2991 3.70078C12.3943 3.79617 12.4619 3.91545 12.495 4.04606C12.5284 4.17643 12.526 4.31341 12.4882 4.44257C12.4504 4.57173 12.3784 4.68832 12.28 4.78006L9.05995 8.00006L12.28 11.2201C12.3787 11.3117 12.4508 11.4283 12.4889 11.5576C12.5269 11.6868 12.5293 11.8239 12.496 11.9545C12.4626 12.085 12.3946 12.2041 12.2992 12.2992C12.2038 12.3944 12.0846 12.462 11.954 12.4951C11.8236 12.5285 11.6866 12.5261 11.5574 12.4883C11.4283 12.4505 11.3117 12.3785 11.22 12.2801L7.99995 9.06006L4.77995 12.2801C4.63769 12.4125 4.44964 12.4846 4.25532 12.4812C4.061 12.4779 3.87555 12.3993 3.73795 12.2621C3.60071 12.1245 3.52215 11.939 3.5188 11.7447C3.51544 11.5504 3.58754 11.3623 3.71995 11.2201L6.93995 8.00006L3.71995 4.78006C3.5795 4.63943 3.50061 4.44881 3.50061 4.25006C3.50061 4.05131 3.5795 3.86068 3.71995 3.72006Z" fill="#171725"/>
              </svg>
            </button>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <label for="">Nome*</label>
              <input type="text" placeholder="Nome da Categoria" v-model="category.description">
              <button class="btn btn-blue mt-2 mx-auto" @click=" category.id ? updateCategory() : addCategory()">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_1_1170)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9549 4.25916C18.1893 4.49357 18.3209 4.81146 18.3209 5.14291C18.3209 5.47437 18.1893 5.79225 17.9549 6.02666L8.58577 15.3958C8.46196 15.5197 8.31496 15.6179 8.15317 15.6849C7.99139 15.752 7.81798 15.7865 7.64286 15.7865C7.46774 15.7865 7.29433 15.752 7.13254 15.6849C6.97076 15.6179 6.82376 15.5197 6.69994 15.3958L2.04494 10.7417C1.92555 10.6264 1.83032 10.4884 1.76481 10.3359C1.6993 10.1834 1.66482 10.0194 1.66338 9.85341C1.66194 9.68744 1.69356 9.52284 1.75641 9.36922C1.81926 9.2156 1.91208 9.07603 2.02945 8.95867C2.14681 8.8413 2.28638 8.74849 2.44 8.68563C2.59362 8.62278 2.75822 8.59116 2.92419 8.5926C3.09017 8.59404 3.25419 8.62852 3.4067 8.69403C3.5592 8.75955 3.69713 8.85477 3.81244 8.97416L7.64244 12.8042L16.1866 4.25916C16.3027 4.143 16.4405 4.05086 16.5922 3.98799C16.7439 3.92512 16.9066 3.89276 17.0708 3.89276C17.235 3.89276 17.3976 3.92512 17.5493 3.98799C17.701 4.05086 17.8389 4.143 17.9549 4.25916Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_1170">
                  <rect width="20" height="20" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                Salvar Categoria
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Bloco Modal -->
    <div class="modal fade" id="deleteBlockModal" tabindex="-1" role="dialog" aria-labelledby="deleteBlockModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content modal-category">
          <div class="d-flex align-items-center justify-content-between border-bottom">
            <h5 class="fw-600 fs-16 mb-0" id="blockModalTitle">Deseja mesmo deletar esse bloco?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <!-- <span aria-hidden="true">&times;</span> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.71995 3.72006C3.86058 3.57961 4.0512 3.50072 4.24995 3.50072C4.4487 3.50072 4.63933 3.57961 4.77995 3.72006L7.99995 6.94006L11.22 3.72006C11.3116 3.62133 11.4282 3.54916 11.5575 3.51115C11.6867 3.47313 11.8238 3.47068 11.9544 3.50406C12.0849 3.53743 12.204 3.60539 12.2991 3.70078C12.3943 3.79617 12.4619 3.91545 12.495 4.04606C12.5284 4.17643 12.526 4.31341 12.4882 4.44257C12.4504 4.57173 12.3784 4.68832 12.28 4.78006L9.05995 8.00006L12.28 11.2201C12.3787 11.3117 12.4508 11.4283 12.4889 11.5576C12.5269 11.6868 12.5293 11.8239 12.496 11.9545C12.4626 12.085 12.3946 12.2041 12.2992 12.2992C12.2038 12.3944 12.0846 12.462 11.954 12.4951C11.8236 12.5285 11.6866 12.5261 11.5574 12.4883C11.4283 12.4505 11.3117 12.3785 11.22 12.2801L7.99995 9.06006L4.77995 12.2801C4.63769 12.4125 4.44964 12.4846 4.25532 12.4812C4.061 12.4779 3.87555 12.3993 3.73795 12.2621C3.60071 12.1245 3.52215 11.939 3.5188 11.7447C3.51544 11.5504 3.58754 11.3623 3.71995 11.2201L6.93995 8.00006L3.71995 4.78006C3.5795 4.63943 3.50061 4.44881 3.50061 4.25006C3.50061 4.05131 3.5795 3.86068 3.71995 3.72006Z" fill="#171725"/>
              </svg>
            </button>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <!-- <label for="">Nome*</label>
              <input type="text" placeholder="Nome da Categoria" v-model="category.description"> -->
              <button class="btn btn-blue mt-2 mx-auto" @click="deleteBlock()">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_1_1170)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9549 4.25916C18.1893 4.49357 18.3209 4.81146 18.3209 5.14291C18.3209 5.47437 18.1893 5.79225 17.9549 6.02666L8.58577 15.3958C8.46196 15.5197 8.31496 15.6179 8.15317 15.6849C7.99139 15.752 7.81798 15.7865 7.64286 15.7865C7.46774 15.7865 7.29433 15.752 7.13254 15.6849C6.97076 15.6179 6.82376 15.5197 6.69994 15.3958L2.04494 10.7417C1.92555 10.6264 1.83032 10.4884 1.76481 10.3359C1.6993 10.1834 1.66482 10.0194 1.66338 9.85341C1.66194 9.68744 1.69356 9.52284 1.75641 9.36922C1.81926 9.2156 1.91208 9.07603 2.02945 8.95867C2.14681 8.8413 2.28638 8.74849 2.44 8.68563C2.59362 8.62278 2.75822 8.59116 2.92419 8.5926C3.09017 8.59404 3.25419 8.62852 3.4067 8.69403C3.5592 8.75955 3.69713 8.85477 3.81244 8.97416L7.64244 12.8042L16.1866 4.25916C16.3027 4.143 16.4405 4.05086 16.5922 3.98799C16.7439 3.92512 16.9066 3.89276 17.0708 3.89276C17.235 3.89276 17.3976 3.92512 17.5493 3.98799C17.701 4.05086 17.8389 4.143 17.9549 4.25916Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_1170">
                  <rect width="20" height="20" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                Deletar bloco
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <ImageCropper
    :url="imageCropper"
    @result="getResult"
    :width="width"
    :height="height"
    :aspectRatio="aspectRatio"/> -->

  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent.vue'
import TextBlock from './components/TextBlock.vue'
import MidiaBlock from './components/MidiaBlock.vue'
import ButtonBlock from './components/ButtonBlock.vue'
import TextMidiaBlock from './components/TextMidiaBlock.vue'
// import TextButtonBlock from './components/TextButtonBlock.vue'
// import TextButtonMidiaBlock from './components/TextMidiaButtonBlock.vue'
// import FormBlock from './components/FormBlock.vue'
// import TextFormBlock from './components/TextFormBlock.vue'
// import MidiaFormBlock from './components/MidiaFormBlock.vue'

export default {
  name: 'CreateBlogPage',

  components: {
    LoadingComponent,
    TextBlock,
    MidiaBlock,
    ButtonBlock,
    TextMidiaBlock
    // TextButtonBlock,
    // TextButtonMidiaBlock
    // FormBlock
    // TextFormBlock,
    // MidiaFormBlock
  },

  data () {
    return {
      blog: {
        blocks: [],
        status: 0,
        scheduled_at: null,
        coverImage: {
          content: [
            {
              type: 'midia'
            }
          ]
        }
      },
      imageCropper: '',
      originalImage: null,
      height: null,
      width: null,
      aspectRatio: null,
      show_image: null,
      block: {
        id: null,
        type: '',
        content: null
      },
      breadcrumb_search: [],
      searchCategory: null,
      show_text_block: false,
      show_midia_block: false,
      show_button_block: false,
      show_text_midia_block: false,
      show_text_button_block: false,
      show_form_block: false,
      show_text_form_block: false,
      show_midia_form_block: false,
      button: {},
      category: {},
      details: {
        status: 1,
        name: null,
        fileName: 'Nenhum arquivo selecionado',
        search: ''
      },
      destroy_block: null
    }
  },

  computed: mapState({
    categories: state => state.blogCategories.data,
    currentCategory: state => state.blogCategories.details,
    breadcrumbs: state => state.blogCategories.breadcrumbs,
    loading: state => state.products.loading,
    resetSearch: state => state.blogCategories.resetSearch,
    article: state => state.blogs.data,
    filteredItems () {
      const query = this.search.toLowerCase()
      return this.items.filter(item => item.name.toLowerCase().includes(query))
    }
  }),

  watch: {
    breadcrumbs: function (newValue) {
      if (newValue.length > 0) {
        this.blog.category_id = newValue[newValue.length - 1].id
      }
    },
    article: function (newValue) {
      if (newValue.id) {
        this.blog = newValue
      }
    },
    resetSearch: function (newValue) {
      if (newValue === 'reset') {
        this.searchCategory = null
      }
    }
  },

  methods: {

    ...mapActions('blogs', [
      'storeBlog',
      'showBlog',
      'updateBlog'
    ]),

    ...mapActions('blogCategories', [
      'index',
      'store',
      'update',
      'destroy',
      'show',
      'back',
      'findCategories',
      'setBreadcrumbs',
      'getBlogBreadcrumbs'
    ]),

    openCreateModal: function () {
      this.category = {
        description: null,
        parent_id: this.currentCategory.id ? this.currentCategory.id : null
      }
      $('#createCategoryModal').modal('show')
    },

    addCategory: function () {
      this.store(this.category)
      $('#createCategoryModal').modal('hide')
    },

    updateCategory: function () {
      this.update({ id: this.category.id, data: this.category })
      $('#createCategoryModal').modal('hide')
    },

    updateFileName () {
      const fileInput = this.$refs.fileInput
      if (fileInput.files.length > 0) {
        this.details.fileName = fileInput.files[0].name
      } else {
        this.details.fileName = 'Nenhum arquivo selecionado'
      }
    },

    openDeleteModal (block) {
      this.destroy_block = block
      $('#deleteBlockModal').modal()
    },

    deleteBlock () {
      console.log(this.destroy_block)
      if (this.destroy_block.item) {

      } else if (this.destroy_block.col) {

      } else if (this.destroy_block.row <= 0) {
        this.blog.blocks.splice(this.destroy_block.row, 1)
      }
      $('#deleteBlockModal').modal('hide')
    },

    addBlock (param) {
      // $('#addBlockModal').modal('show')
      this.block = {
        id: null,
        type: '',
        content: null
      }

      if (param === 'text' || param === 'midia' || param === 'button') {
        const id = Math.floor(Math.random() * 1000)

        this.block = [
          [{
            id: id.toString(),
            type: param,
            content: ''
          }]
        ]

        this.blog.blocks.push(this.block)
      }

      if (param === 'text_midia') {
        const id = Math.floor(Math.random() * 1000)

        this.block = [
          [{
            id: id.toString(),
            type: 'text',
            content: []
          }],
          [{
            id: id.toString(),
            type: 'midia',
            content: []
          }]
        ]

        this.blog.blocks.push(this.block)
      }

      if (param === 'text_midia_button') {
        const id = Math.floor(Math.random() * 1000)

        this.block = [
          [{
            id: id.toString(),
            type: 'text',
            content: []
          },
          {
            id: id.toString(),
            type: 'button',
            content: []
          }],
          [{
            id: id.toString(),
            type: 'midia',
            content: []
          }]
        ]

        this.blog.blocks.push(this.block)
      }

      if (param === 'text_button') {
        const id = Math.floor(Math.random() * 1000)

        this.block = [
          {
            id: id.toString(),
            type: param,
            content: []
          },
          {
            id: id.toString(),
            type: param,
            content: []
          }
        ]

        this.blog.blocks.push(this.block)
      }

      $('#blockModal').modal('hide')
    },

    setCategoryBreadcrumb (category) {
      this.breadcrumb_search = []

      this.breadcrumb_search.push(category)

      if (category.subcategories) {
        category.subcategories.forEach(subcategory => {
          this.breadcrumb_search.push(subcategory)
        })
      }

      return this.breadcrumb_search
    }
  },

  mounted () {
    if (this.$route.params.id) {
      this.showBlog(this.$route.params.id)
      this.getBlogBreadcrumbs(this.$route.params.id)
    } else {
      this.index({
        page: 1,
        name: null
      })
    }
  }
}

</script>
<style scoped>
  hr {
    border-top: 1px solid #E2E2EA;
  }
  .card {
    border-radius: 20px;
    border: 1px solid #E2E2EA !important;
    background: #FFF;
    padding: 32px;
  }
    /* font-size: 28px; */
  /* input, select {
    font-weight: 600;
    border: 0;
    color: #171725;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    border: 1px solid transparent;
    transition: .3s;
  }
  input:hover {
    border-radius: 4px;
    border: 1px solid #E2E2EA;
    color: #171725;
    transition: .3s;
  }
  */
  input[type="radio"] {
    cursor: pointer;
  }
  input.type-1 {
    border-radius: 4px;
    border: 1px solid #E2E2EA;
    color: #696974 !important;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 24px 4px 8px;
  }
  input::placeholder {
    color: #696974;
  }
  .card-title {
    font-weight: 600;
    font-size: 16px;
    /* margin-bottom: 0; */
  }
  label {
    display: block;
    color: #696974;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  label.radio {
    color: #171725;
    font-size: 16px;
    font-weight: 600;
    padding-left: 8px;
    margin-bottom: 0;
    cursor: pointer;
  }

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.file-label {
  color: #171725;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  /* display: inline-block;
  cursor: pointer; */
  /* padding: 5px 10px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px; */
}

.file-name {
  margin-top: 5px;
}
.modal-content {
  margin-top: 0 !important;
  border-radius: 20px;
  border: 0;
  padding: 32px;
}
.modal-content.modal-category {
  margin-top: 0 !important;
  border-radius: 5px;
  border: 0;
  padding: 16px;
}
.block, .block img {
  transition: .3s;
}
.block:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.block:hover img {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.25);
  transition: .3s;
}
.btn-blocks {
  border-radius: 10px;
  background-color: #F2F7FF;
  color: #0062FF;
  min-height: 38px;
  display: block;
  width: 100%;
  padding: 8px 14px;
}
.btn-collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
}
.position-search-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-50%, -50%);
}
.btn-blue {
  padding: 3px 8px;
  font-size: 12px;
  white-space: nowrap;
}
.btn-home {
  /* border-radius: 5px 5px 0px 0px; */
  background: #0062ff1a;
  padding: 0;
  height: 26px;
}
.btn-home svg {
  margin: 5px;
}
.category {
  display: flex;
  align-items: center;
  height: 26px;
  color: #0062FF;
  padding: 4px;
  background-color: #0062ff0d;
  margin-bottom: 0;
}
</style>
